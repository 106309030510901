:root {
  --version: 1.39;
}

footer .content {
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(6, 52, 98, 0.8)), to(#063462));
  background: linear-gradient(rgba(6, 52, 98, 0.8), #063462);
}
@media (min-width: 992px) {
  footer div + .content {
    padding-top: 250px;
    padding-bottom: 50px;
  }
}
footer .newsletter-checkbox .form-check {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}
footer .newsletter-checkbox .form-check input {
  flex-shrink: 0;
}
